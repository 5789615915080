<template>
  <div id="app">

    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    data() {
      return {

      }
    },
    mounted() {
      var t = window.devicePixelRatio   // 获取下载的缩放 125% -> 1.25    150% -> 1.5
      if (t != 1) {   // 如果进行了缩放，也就是不是1
        document.body.style.zoom = -0.6 * t + 1.55;   // 就去修改页面的缩放比例，这个公式我自己算的，不准确，勉强。
      }
      if (t == 1) {
        document.body.style.zoom = -0.6 * 1.25 + 1.55;
      }
    }

  }
</script>

<style lang="scss">
  @import './assets/css/common.scss';
  @import url(./assets/css/animation.css);
  *{ 
 -webkit-touch-callout:none; /*系统默认菜单被禁用*/ 
 -webkit-user-select:none; /*webkit浏览器*/ 
 -khtml-user-select:none; /*早期浏览器*/ 
 -moz-user-select:none;/*火狐*/ 
 -ms-user-select:none; /*IE10*/ 
 user-select:none; 
} 
input{ 
 -webkit-user-select:auto; /*webkit浏览器*/  
}
textarea{
 -webkit-user-select:auto; /*webkit浏览器*/
}


</style>