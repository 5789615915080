import Vue from 'vue'
import VueRouter from 'vue-router'
import { Loading } from 'element-ui';
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/index/index.vue')
  },
  {
    path: '/article',
    name: 'article',
    component: () => import('../views/release/article.vue')
  },
  {
    path: '/articleRevise',
    name: 'articleRevise',
    component: () => import('../views/release/articleRevise.vue')
  },
  {
    path: '/video',
    name: 'video',
    component: () => import('../views/release/video.vue')
  },
  {
    path: '/videoRevise',
    name: 'videoRevise',
    component: () => import('../views/release/videoRevise.vue')
  },
  {
    path: '/publishList',
    name: 'publishList',
    component: () => import('../views/release/publishList.vue')
  },
  {
    path: '/videoInfo',
    name: 'videoInfo',
    component: () => import('../views/info/videoInfo.vue')
  },
  {
    path: '/articleInfo',
    name: 'articleInfo',
    component: () => import('../views/info/articleInfo.vue')
  },
  {
    path: '/recommendInfo',
    name: 'recommendInfo',
    component: () => import('../views/info/recommendInfo.vue')
  },
  {
    path: '/homePersonal',
    name: 'homePersonal',
    component: () => import('@/views/index/homePersonal.vue')
  }

]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, position) => {
    if (position) {
      return position
    } else {
      return { x: 0, y: 0 }
    }
  }
})


router.beforeEach((to, from, next) => {
  const loading = Loading.service({
    lock: true,
    text: 'Loading',
  });
  setTimeout(() => {
    loading.close();
  }, 500);
  next()

})


export default router
