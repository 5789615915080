import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import {axios,axiosB} from '@/assets/js/axios'
import getDateTimeFormat from '@/assets/js/sumo_time'

// import VueQuillEditor from 'vue-quill-editor'
// import 'quill/dist/quill.core.css'
// import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'
// Vue.use(VueQuillEditor)

Vue.prototype.getDateTime=getDateTimeFormat
Vue.prototype.$axios=axios
Vue.prototype.$axiosA=axiosB
import './assets/element/index.css'
Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
