import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    http: 'https://www.aner.vip/apih5/',
    upload: 'https://www.aner.vip/apih5/uploadImage',
    modifyInfo: {},//修改对象
   
  },
  getters: {
  },
  mutations: {
   
  },
  actions: {
  },
  modules: {
  }
})
