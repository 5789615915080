import Vue from 'vue'
import axiosA from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axiosA)


export const axios = axiosA.create({
    baseURL: ' https://www.aner.vip/apih5/',
    //  baseURL: 'http://192.168.1.192:8085/apih5/',
    method: 'POST',
    header: {

        'Content-Type': 'application/json;charset=utf-8'
    },
})

export const axiosB = axiosA.create({



})





